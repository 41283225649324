import type { Operation } from '@apollo/client'
import { ApolloLink, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ErrorCode } from '@retire/constants/errors'
import { API_URL } from '@retire/utils/api/constants'
import { isTest } from '@retire/utils/config'
import { reportRollbarError } from '@retire/utils/errors'
import { endSession, isActiveSession, navigateToSignIn } from '@retire/utils/session'
import { getPathname } from '@retire/utils/url'

import { DEFAULT_MAX_RETRIES, Header } from './constants'
import { pendingRequestsCounter, retriesCounter, sessionExpirationTime } from './vars'

const getUniqKeyFromOperation = ({ operationName, variables }: Operation) =>
  Object.values(variables).length ? `${operationName}-${JSON.stringify(variables)}` : operationName

// handle API call counters
// - increment counters when API call is started
// - decrement pending counter when API call is done (except in case of network errors)
// - reset retries counter when API call is done
export const countersLink = new ApolloLink((operation, forward) => {
  const maxRetries = operation.getContext().maxRetries || DEFAULT_MAX_RETRIES
  const uniqueKey = getUniqKeyFromOperation(operation)
  pendingRequestsCounter(pendingRequestsCounter() + 1)
  // eslint-disable-next-line no-console
  isTest() && console.log(`Retries counter: ${JSON.stringify(retriesCounter())}`)
  if (retriesCounter()[uniqueKey] >= maxRetries) {
    const networkError = new Error('Maximum number of retries has been reached')
    networkError.name = ErrorCode.retries
    throw networkError
  }

  return forward(operation).map(({ data, errors }) => {
    pendingRequestsCounter(pendingRequestsCounter() - 1)
    !errors &&
      retriesCounter({
        ...retriesCounter(),
        [uniqueKey]: 0,
      })
    return { data, errors }
  })
})

// handle session expiration time
export const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext()
    const {
      response: { headers },
    } = context
    headers && sessionExpirationTime(headers.get(Header.sessionExpirationTime))

    return response
  })
})

// handle API call errors
// - decrement pending API calls
// - console error logs if any
// - redirect to sign-in page if session is expired
export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const uniqueKey = getUniqKeyFromOperation(operation)
  retriesCounter({
    ...retriesCounter(),
    [uniqueKey]: (retriesCounter()[uniqueKey] || 0) + 1,
  })
  if (graphQLErrors) {
    graphQLErrors.map(graphQLError => {
      const { message, locations, path, extensions } = graphQLError
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      if (extensions?.code !== ErrorCode.processing) {
        // do not report the processing errors
        reportRollbarError(graphQLError, operation)
      }
    })

    // handle expired session
    if (graphQLErrors.some(error => error.extensions?.code === ErrorCode.unauthorized)) {
      if (isActiveSession()) {
        endSession()
      }
      // init/getPublicSettings queries are used on not/protected route level
      // so, we don't want to do redirection to sign-in in these cases
      if (!['init', 'getPublicSettings'].includes(operation.operationName)) {
        navigateToSignIn(getPathname())
      }
    }
  }
  if (networkError) {
    pendingRequestsCounter(pendingRequestsCounter() - 1)
    console.error(`[Network error]: ${networkError} (${uniqueKey})`)
  }
})

// handle API call processing errors: edit the message with a counter index
// this is necessary to be sure onError callback are correctly called each time
export const processingLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(({ data, errors }) => {
    const uniqueKey = getUniqKeyFromOperation(operation)
    const currentCounter = retriesCounter()[uniqueKey] ?? 0
    const editedErrors = errors
      ? errors.map(error => {
          if (error.extensions.code === ErrorCode.processing) {
            error.message = `${error.message} - n${currentCounter + 1}`
          }
          return error
        })
      : []
    return { data, errors: editedErrors }
  })
})

// handle API call itself
export const httpLink = new HttpLink({
  uri: `${API_URL}/graphql`,
  credentials: 'include',
})
