import { ErrorCode } from '@retire/constants'
import type { TGeneralError } from '@retire/types'
import { isGlobalErrorCode, navigateToSignIn } from '@retire/utils'
import type { FC } from 'react'
import { createContext, useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import type { TGlobalErrorContext } from './types'

export const GlobalErrorContext = createContext<TGlobalErrorContext>({
  globalError: undefined,
  setGlobalError: () => null,
  resetGlobalError: () => null,
})

export const GlobalErrorProvider: FC = ({ children }) => {
  const { pathname } = useLocation()
  const [globalError, setGlobalError] = useState<TGeneralError>()
  const setGlobalErrorIfCode = useCallback(
    error => {
      if (error?.code === ErrorCode.unauthorized) {
        navigateToSignIn(pathname)
      } else if (isGlobalErrorCode(error?.code)) {
        setGlobalError(error)
      }
    },
    [pathname]
  )
  const resetGlobalError = useCallback(() => setGlobalError(undefined), [])

  const value = useMemo(
    () => ({
      globalError,
      setGlobalError: setGlobalErrorIfCode,
      resetGlobalError,
    }),
    [globalError, setGlobalErrorIfCode, resetGlobalError]
  )

  return <GlobalErrorContext.Provider value={value}>{children}</GlobalErrorContext.Provider>
}
