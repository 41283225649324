import { useQuery } from '@apollo/client'
import { INIT_QUERY } from '@retire/gql/user/queries'
import { navigateToSignIn } from '@retire/utils/session'
import { useLocation, useNavigate } from 'react-router-dom'

import { paths, statePaths } from '../../paths'
import type { TReturn } from './types'

export const useValidPathsForState = (skipQuery = false): TReturn => {
  const { loading, data, error } = useQuery(INIT_QUERY, { skip: skipQuery })
  const navigate = useNavigate()
  const { pathname } = useLocation()

  if (error) {
    try {
      navigateToSignIn(pathname)
    } catch (_error) {
      navigate(paths.hygienePages.serverError)
    }
    return {
      loading: true,
      error,
    }
  } else if (loading) {
    return {
      loading: true,
    }
  } else {
    return {
      loading: false,
      validPaths: data?.user.state ? statePaths[data.user.state] : undefined,
    }
  }
}
