import { USER_FULL_DETAILS_QUERY } from '@retire/gql/user/queries'
import { isActiveSession } from '@retire/utils'

import { useQueryWithError } from '../useQueryWithError'

export const useIsEnsignUser = () => {
  const { data } = useQueryWithError(USER_FULL_DETAILS_QUERY, {
    skip: !isActiveSession(),
  })

  return data?.user.employer?.masterTrust === 'Ensign'
}
